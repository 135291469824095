@import "theme/variables.scss";

.catcher {
  margin-top: 10px;
  padding: 12px;
  border: 1px solid $border-grey;
  border-radius: 3px;
  background-color: $color-white;
  color: $color-dark-grey;
  text-align: center;
}
