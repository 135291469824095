/** 
 * Generic
 */

html {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
}

input[type="text"]:focus,
input[type="number"]:focus,
textarea:focus {
  font-size: 16px;
}

/* disable auto-zoom on iphone input field focus */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 16px;
  }
}

.viewportHeight {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

.food_name,
.food_modifier {
  padding: 0 3px;
  background-color: #c2fff1;
}

.food_modifier {
  background-color: #d0dfff;
}

.wavesurfer-region {
  border-left: 2px solid #171717;
  border-right: 2px solid #171717;
}

.wavesurfer-region:before {
  content: attr(data-region-label);
  position: absolute;
  top: 0;
  left: 5px;
  font-size: 12px;
}

.no-scroll {
  overflow: hidden;
}

.chat_handle {
  padding: 0 5px;
  border-radius: 6px;
  background-color: #d0dfff;
  color: rgba(0, 0, 0, 0.54);
}
