@font-face {
  font-family: "RedHatRegular";
  font-style: "normal";
  font-display: "swap";
  font-weight: 400;
  src: local("Red Hat Display Regular"), local("Red-Hat-Display-Regular"),
    url(./fonts/RedHatRegular.ttf) format("truetype");
}

@font-face {
  font-family: "RedHatMedium";
  font-style: "normal";
  font-display: "swap";
  font-weight: 500;
  src: local("Red Hat Display Medium"), local("Red-Hat-Display-Medium"),
    url(./fonts/RedHatMedium.ttf) format("truetype");
}

@font-face {
  font-family: "RedHatBold";
  font-style: "normal";
  font-display: "swap";
  font-weight: 700;
  src: local("Red Hat Display Bold"), local("Red-Hat-Display-Bold"),
    url(./fonts/RedHatBold.ttf) format("truetype");
}
